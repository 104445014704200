<!-- loading data layout start -->
<div class="loading-screen text-center vh-100 d-flex align-items-center justify-content-center" *ngIf="loadingData">
  <div>
      <img class="dino-loading-img" src="/assets/images/quote_monster_crunching_animation.gif?v=2">
      <h3>Loading data.</h3>
  </div>
</div>
<!-- loading data layout end -->


<!-- after loading data layout start -->
<ng-container *ngIf='loadingData === false'>
  <div class="container-fluid">

    <div id='qmMainTopHeader' class="qm-header">
      <nav-menu></nav-menu>
    </div>

    <!-- here is main container start-->
    <router-outlet></router-outlet>
    <!-- here is main container end -->

    <footer id='qmMainBottomFooter' class="row text-white qm-bg-darkGray py-block footer">
      <div class='container-fluid px-5'>
        <div class="row mb-5">
          <!-- social network -->
          <div class="col-lg-6 mb-4 mb-lg-0 text-center text-lg-left">
            <a mat-fab color="qm-black" class="mr-3 mb-3"
              href="https://www.linkedin.com/company/quality-product-research-ltd" target="_blank" rel="noopener"
              referrerpolicy="no-referrer">
              <img width="24" height="24" src="../assets/images/icon/linkedin-white@2x.png?v=2" title='LinkedIn' />
            </a>
            <a mat-fab color="qm-black" class="mr-3 mb-3" href="https://twitter.com/QPRQuotemonster" target="_blank"
              rel="noopener" referrerpolicy="no-referrer">
              <img width="24" height="20" src="../assets/images/icon/twitter-white@2x.png?v=2" title='Twitter' />
            </a>
            <a mat-fab color="qm-black" class="mr-0 mr-sm-5 mb-3" href="https://www.youtube.com/channel/UC9gx8xqu7plB7RylVBm-BNg" target="_blank"
            rel="noopener" referrerpolicy="no-referrer">
            <img width="30" height="30" src="../assets/images/icon/youtube-white.png?v=2" title='Youtube' />
            </a>
            <div class="d-block d-sm-none"></div>
          </div>
          <!-- social network -->
          <!-- logo -->
          <div class="col-lg-6 text-center text-lg-right">
            <img width="257" height="84" src="../assets/images/qpr-footer-logo/qpr-footer-logo@2x.png?v=2" alt="qpr logo">
          </div>
          <!-- logo -->
        </div>
      </div>

      <!-- copyright & site version & terms-->
      <div class="w-100 border-top border-dark">
        <div class="container-fluid px-5">
          <div class="row">
            <!-- copyright & site version-->
            <div class="pt-5 col-lg-7 text-center text-lg-left">
              <h6 class="text-white text-uppercase">
                Copyright &copy; {{copyrightYear}} All Rights Reserved by Quality Product Research Limited
              </h6>
              <h6 class="text-white text-uppercase">
                Quotemonster Internal Group Admin &nbsp;&nbsp;| &nbsp;&nbsp; v.1.0.0
              </h6>
            </div>
            <!-- copyright & site version end-->
            <!-- terms -->
            <div class="col-lg-5 pt-5 ml-lg-auto text-center text-lg-right">
              <div class="d-flex flex-column justify-content-between h-100">
                <h6 class="text-uppercase">
                  <a class="text-blue" href='/TermsOfUse.html' target="_blank" rel="noopener">
                    Terms of Use & Privacy Policy
                  </a>
                </h6>
                <h6 class="text-uppercase">
                  <a class="text-blue" href={{outsourcePASUrl}} target="_blank" rel="noopener">
                    Outsource Provider Assurance Statement
                  </a>
                </h6>
              </div>
            </div>
            <!-- terms end-->

          </div>
        </div>
      </div>
      <!-- copyright & site version & terms end-->
    </footer>
  </div>
</ng-container>
<!-- after loading data layout end -->


<!-- dev mode only start-->
<!-- <div
  class='row m-0 alert alert-danger text-center d-block bottom-fixed'
  *ngIf='isDevMode'>
  --- TEST SITE UI V2 for new API---
</div> -->
<!-- dev mode only end-->

<!-- SystemNotices start -->
<div class='row m-0 alert alert-danger text-center bottom-fixed rounded-0'
  *ngIf='systemNoticesList.length > 0' [ngClass]="hideSystemNotices?'d-none':'d-block'">
  <button mat-icon-button class="float-left qm-icon-btn-sm" (click)="hideSystemNotices = !hideSystemNotices">
    <span class="material-icons" aria-label="close">clear</span>
  </button>
  <ng-container *ngFor="let notice of systemNoticesList">
    <p class="mb-0"><strong>{{notice.Subject}}</strong>: {{notice.Contents}}</p>
  </ng-container>
</div>
<!-- SystemNotices end -->
