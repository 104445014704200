import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../service/login.service';
import { ComponentBaseClass } from '../../service/base';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseUserModel, DeviceCodeItem } from 'src/app/models/user';
import { GeneralResponseMessage } from 'src/app/models/messages/general.response.message';
import { MfaVCode } from 'src/app/models/access.token.models/mfa-vcode.model';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'verify-device',
    templateUrl: './verify-device.component.html',
    styleUrls: ['./verify-device.component.scss'],
})
export class VerifyDeviceComponent extends ComponentBaseClass implements OnInit {
    userModel: BaseUserModel;
    mfaInfo: GeneralResponseMessage;
    message: string;
    currentLoginUserId: number;
    currentDeviceCode: string;
    reSendCodeTimmer: number;
    verificationCode: string;
    timeInterval: any;
    mfaMethodCode: string;
    userDeviceCodeItems: DeviceCodeItem[];
    savedUserDeviceCode: string = '';
    isAddingMfaMethod: boolean = false;
    hasTrustedDevice: boolean = true;

    constructor(
        public loginService: LoginService,
        private router: Router,
        private route: ActivatedRoute,
        public sharedFunction: SharedFunctionService,
    ) {
        super();
        this.getMFAMethodCode();
    }

    ngOnInit() {
        // check is adding MFA method or not
        this.getMFAMethodCode();

        // 1. get current user id
        this.currentLoginUserId = this.loginService.getCurrentLoginUserId();
        
        // 2. get current device code
        this.currentDeviceCode = this.loginService.getDeviceCode();
        
        // 3. get current login mfa info
        this.mfaInfo = this.loginService.getLoginMfaInfo(this.currentLoginUserId);
        if (!this.mfaInfo || !this.mfaInfo.Message) {
            // if no validated message then back to login.
            this.router.navigate(['/login']);
            return;
        }
        
        // TOTP does not have resend function, do not need to start timer
        if (this.mfaInfo.ExtValue !== 'TOTP') {
            this.startTimer();
        }
        
    }

    checkVerificationCode(): void {
        if (this.mfaMethodCode) {
            this.checkMfaVerificationMethod();
        } else {
            this.checkDeviceVerificationMethod();
        }
    }

    resendVerificationCode(): void {
        if (this.mfaMethodCode) {
            this.resendMfaVerificationCode();
        } else {
            this.resendDeviceVerificationCode();
        }
    }

    checkDeviceVerificationMethod() {
        let userModel = new BaseUserModel();
        userModel.UserId = this.currentLoginUserId;
        userModel.DeviceCode = this.currentDeviceCode;
        userModel.VerificationCode = this.verificationCode;

        this.showDinoLoading();
        
        this.loginService.doCheckDeviceVerificationCode(userModel, (response) => {
            // LOGIN error
            if (response && response.Messages && response.Messages.length) {
                for (let m of response.Messages) {
                    this.message = this.message + m.Message + '</ br>';
                }
            } else if (response && response.RefreshToken && response.RefreshToken.Token) {
                // check user access token and refresh token
                // save refresh token only
                this.loginService.saveUserToken(response);
                this.verifyVCodePassed(response.UserId);
            } else if (response && response.MfaInfo && response.MfaInfo.MessageCode !== 200) {
                // MFA error
                this.message = response.MfaInfo.Message;
            }
            this.closeDinoLoading();
        });
    }

    resendDeviceVerificationCode() {
        this.showDinoLoading();

        let userModel = new BaseUserModel();
        userModel.DeviceCode = this.currentDeviceCode;
        userModel.UserId = this.currentLoginUserId;

        this.loginService.doResendDeviceVerificationCode(userModel, (response) => {
            this.doneResentVCode(response);
        });

        this.startTimer();
    }

    checkMfaVerificationMethod() {
        let mfaVCodeObj = new MfaVCode();
        mfaVCodeObj.MethodCode = this.mfaMethodCode;
        mfaVCodeObj.VCode = this.verificationCode;
        mfaVCodeObj.DeviceCode = this.currentDeviceCode;
        mfaVCodeObj.UserId = this.currentLoginUserId;
        this.showDinoLoading();
        this.loginService.doCheckMfaVerificationCode(mfaVCodeObj, (response) => {
            if (response && response.MessageCode === 200) {
                let requestData = {
                    RefreshToken: {
                        Token: response.ExtValue,
                    },
                    UserId: this.currentLoginUserId,
                };

                // call getPaRefreshAccessToken api to get token
                this.loginService.getRefreshAccessToken(requestData, (response) => {
                    // update access token, refresh token, user info
                    response.UserName = this.loginService.getCurrentLoginUserName();
                    this.loginService.saveUserToken(response);

                    this.verifyVCodePassed(this.loginService.getCurrentLoginUserId());
                    this.closeDinoLoading();
                });
            } else if (response && response.MessageCode !== 200) {
                this.message = response.Message;
                this.closeDinoLoading();
            }
         
        });
    }

    resendMfaVerificationCode(): void {
        this.showDinoLoading();
        let mfaVCode = new MfaVCode();
        mfaVCode.DeviceCode = this.currentDeviceCode;
        mfaVCode.UserId = this.currentLoginUserId;
        mfaVCode.MethodCode = 'EMAIL';
        this.loginService.doResendMfaVerificationCode(mfaVCode, (response) => {
            this.doneResentVCode(response);
        });

        this.startTimer();
    }

    startTimer() {
        this.reSendCodeTimmer = 300;
        this.timeInterval = setInterval(() => {
            this.reSendCodeTimmer = this.reSendCodeTimmer - 1;
            if (this.reSendCodeTimmer <= 0) {
                clearInterval(this.timeInterval);
            }
        }, 1000);
    }

    getMFAMethodCode(): void {
        this.route.queryParams.subscribe((params) => {
            if (params && params.methodcode) {
                this.mfaMethodCode = params.methodcode.toUpperCase();
                this.isAddingMfaMethod = true;
            } else {
                this.mfaMethodCode = '';
                this.isAddingMfaMethod = false;
            }
        });
    }

    verifyVCodePassed(userId: number): void {
        // clear MFA data
        this.loginService.clearLoginMfaInfo();
        // go to view invoice page
        this.router.navigate(['/group-members']);
    }

    doneResentVCode(response: GeneralResponseMessage): void {
        this.message = '';
        if (response.MessageCode && response.MessageCode === 200) {
            this.sharedFunction.openSnackBar('VerifyDevice-SUCCESS-ResendVCode', 'OK', 2000);
        } else if (response.Message && response.Message) {
            this.message = response.Message;
        }
        this.closeDinoLoading();
    }
    
    saveDeviceCode(deviceCode:string): void {
        // save device code item
        this.userDeviceCodeItems = this.loginService.getDeviceCodeItems();
        this.savedUserDeviceCode = this.loginService.getDeviceCodeFromQmDeviceCode();
        // Standard login
        let userId = this.currentLoginUserId;
        
        
        this.loginService.updateDeviceCodeItems(
            this.userDeviceCodeItems,
            this.loginService.getCurrentLoginUserName(),
            userId,
            deviceCode,
        );
        // remove device code saved in old way
        if (this.savedUserDeviceCode) {
            this.loginService.removeDeviceCode();
        }
    }
}
