export class GeneralResponseMessage {
    MessageCode: number;
    Message: string;
    ExtValue: string;
}

export class DetailResponseMessage {
    AsyncState: string | null;
    CreationOptions: number;
    Exception: string | null;
    Id: number;
    IsCanceled: boolean;
    IsCompleted: boolean;
    IsCompletedSuccessfully: boolean;
    IsFaulted: boolean;
    Result: GeneralResponseMessage;
    Status: number;
}