import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { VerifyDeviceComponent } from './components/login/verify-device.component';
import { ResetPasswordComponent } from './components/login/reset-password.component';
import { MfaMethodSelectComponent } from './components/login/mfa-method-select/mfa-method-select.component';
import { ViewInvoiceComponent } from './components/view-invoice/view-invoice.component';
import { AfterLoginGuard } from './tools/after-login.guard';
import { AuthGuard } from './tools/auth.guard';
import { GroupMembersComponent } from './components/group-members/group-members.component';
import { SOATemplateComponent } from './components/soa-template/soa-template.component';

const appRoutes: Routes = [
    {
        path: '',
        redirectTo: 'group-members',
        pathMatch: 'full',
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [AfterLoginGuard],
    },
    {
        path: 'mfa-methods-select',
        component: MfaMethodSelectComponent,
        canActivate: [AfterLoginGuard],
    },
    {
        path: 'verify-device',
        component: VerifyDeviceComponent,
        canActivate: [AfterLoginGuard],
    },
    {
        path: 'resetpassword',
        component: ResetPasswordComponent,
        canActivate: [AfterLoginGuard],
    },
    {
        path: 'pa/resetpassword',
        component: ResetPasswordComponent,
        canActivate: [AfterLoginGuard],
    },
    {
        path: 'group-members',
        component: GroupMembersComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'view-invoice',
        component: ViewInvoiceComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'soa-template',
        component: SOATemplateComponent,
        canActivate: [AuthGuard],
    },

    // all other
    {
        path: '**',
        redirectTo: 'group-members',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            // Router options
            useHash: false,
            preloadingStrategy: PreloadAllModules,
            enableTracing: false,
            initialNavigation: 'enabled',
            anchorScrolling: 'enabled',
            scrollPositionRestoration: 'top',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
