
import { UIMessage } from './ui/ui.message';
import { SelectItemListEntity } from './select.item.list';



export class QuoteRequiredData {
    
    QeVersion: string;      // from server side
    QprVersion: string;   // from server side
    SelectOptions: SelectItemListEntity[]; // from server side

    
    Help: any[];

    SubscriptionPrices: any[];
    User2FactorLoginMessage: any[];

    SpecialHomeTakeOverDate: string;
    SpecialHomeTakeOverImageUrl: string;
    SpecialHomeTakeOverLinkUrl: string;
    SpecialHomeTakeOverTrackingName: string;
    SpecialHomeTakeOverImages: any[];
    ShowThreeBannerOnHome: boolean;
    MainBodyContainerWidth: string;


    ProviderLogoList: any[];

    IncludeBankPolicyFeeInHeadToHead: boolean;

    

    UserDefaultLogo: string;
    UserDefaultAvatar: string;

    UIMessages: UIMessage[];
    InvoiceStatus: any[];
    ResearchReportDisplay: any[];

    TinyMCEApiKey: string;
    

    OutsourceProviderAssuranceStatement: string;

    
    
    HasSkipBtnOnMFA: boolean;
    MfaHelpLink: string;
    QuoteWizardHouseholdPeopleHelpLink: string;
    QuoteWizardHouseholdIncomeHelpLink: string;
    NeedAnalysisScopePriorityNotRequiredNoteList: { Text: string; Checked: boolean }[];

    constructor() {
       
        this.Help = [];

        this.SubscriptionPrices = [];
        this.User2FactorLoginMessage = [];
        this.SpecialHomeTakeOverDate = '';
        this.SpecialHomeTakeOverImageUrl = '';
        this.SpecialHomeTakeOverLinkUrl = '';
        this.SpecialHomeTakeOverTrackingName = '';
        this.SpecialHomeTakeOverImages = [];
        this.ShowThreeBannerOnHome = false;

        this.ProviderLogoList = [];
        

        this.UserDefaultLogo = 'https://quotemonsterclientfile.blob.core.windows.net/userdefaultfile/PlaceYourLogoHere.jpg';
        this.UserDefaultAvatar = '/assets/images/dino.png?v=2';
        this.UIMessages = [];

        this.InvoiceStatus = [];
        this.ResearchReportDisplay = [];

        this.TinyMCEApiKey = '';
        

        this.OutsourceProviderAssuranceStatement = '';
       
        this.HasSkipBtnOnMFA = true;
        this.MfaHelpLink = '';
        this.QuoteWizardHouseholdPeopleHelpLink='';
        this.QuoteWizardHouseholdIncomeHelpLink = '';
        this.NeedAnalysisScopePriorityNotRequiredNoteList = [];

    }
}
