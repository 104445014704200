<div class="row qm-bg-catskillWhite py-block login-page">
  <div class="container text-center">
    <div class="login-card mx-auto mb-5 py-5 px-3 px-sm-5 bg-white col-lg-7">
        <h4 class="mb-4">Quotemonster Internal Group Admin</h4>
        <h2 class="mb-5">Login</h2>

        <ul class="list-group text-left border-none">
          <ng-container *ngIf='showVerificationCode !== true'>
            <form [formGroup]="loginForm" 
                  (ngSubmit)='doLogin(loginForm.value);
                              loginService.doGoogleTracking("InternalGroupAdminLogin", "do login", "");'>
              <li class="list-group-item px-0">
                <mat-form-field>
                  <input matInput type="email" placeholder="Email Address" formControlName="UserName" required
                    value='{{savedUserEmail}}'>
                </mat-form-field>
              </li>

              <li class="list-group-item px-0">
                <mat-form-field>
                  <input matInput [type]="hidePassword ? 'password' : 'text'" formControlName="UserPassword" placeholder="Password" required>
                  <button mat-icon-button type="button" matSuffix (click)="hidePassword = !hidePassword" [disableRipple]="true">
                    <span class="material-icons">{{hidePassword ? 'visibility_off' : 'visibility'}}</span>
                  </button>
                </mat-form-field>
              </li>

              <li class="list-group-item px-0">
                <mat-checkbox formControlName="RememberEmail" checked='{{hasSavedUserEmail}}'>
                  Remember me
                </mat-checkbox>
              </li>

              <li class="list-group-item px-0">
                <button type="submit" mat-flat-button color="qm-blue" class="btn-block qm-flat-btn-lg" [disabled]="!loginForm.valid">
                  Login
                </button>
              </li>
            </form>

            <li class='list-group-item px-0 text-center'>
              <button mat-button class="text-capitalize qm-text-sm"
                (click)='forgottenPassword(loginForm.value);
                         loginService.doGoogleTracking("InternalGroupAdminLogin", "go to forgotten password", "");'>
               Forgotten password?
              </button>
            </li>
          </ng-container>
          <li class="list-group-item px-0" *ngIf="messageLogin">
            <div class="alert alert-danger" role="alert">
              <div [innerHTML]="messageLogin | safeHtml"></div>
            </div>
          </li>
        </ul>
    </div>
  </div>
</div>
