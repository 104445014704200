import { Injectable, isDevMode } from '@angular/core';
import { HttpRequestUrl } from '../models/http.request.models/request.url';



@Injectable({
  providedIn: 'root',
})
export class ApiAddressService {

  private baseUrlTest: string = 'https://sandboxapiv3.quotemonster.co.nz/api/';
  //private baseUrlTest: string = 'http://localhost:4889/api/';
  private baseUrlLive: string = 'https://apiv2.quotemonster.co.nz/api/';
  private version: string = '';


  getBaseUrl(): string {
    if (isDevMode()) {

      return this.baseUrlTest;
    } else {
      return this.baseUrlLive;
    }
  }


  getOldSiteUrl(): string {
    if (isDevMode()) {
      return 'https://qa.quotemonster.co.nz/old/';
    } else {
      return 'https://secure.quotemonster.co.nz/ssl/default.aspx';
    }
  }


  getBlogUrl() {
    return 'https://blog.quotemonster.co.nz/wp-json/wp/v2/posts';
  }



  /** IGAM API address start */ 
  getAccessTokenUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'IGAM/Authorize/GetAccess', 'POST'
    );
  }

  getRefreshAccessTokenUrl() {
    return this.getBaseUrl() + this.version + 'IGAM/Authorize/RefreshAccess';
  }

  getRefreshAccessTokenHttpRequestUrl(): HttpRequestUrl {
    return new HttpRequestUrl(this.getBaseUrl() + this.version + 'IGAM/Authorize/RefreshAccess', 'POST');
  }
    
  // get user available MFA methods
  getAvailableMfaMethodsUrl(userId: number, deviceCode: string): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'IGAM/Authorize/MFAs?userId=' + userId + '&deviceCode=' + deviceCode, 'GET'
    );
  }

  // add user MFA method
  addMfaMethodUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'IGAM/Authorize/MFA', 'POST'
    );
  }

  resendMfaVerificationCodeUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'IGAM/Authorize/MFA/ResendVCode', 'POST'
    );
  }
    
  getRequestReSetPasswordLinkUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'IGAM/Authorize/ReSetPassword', 'POST'
    );
  }

  getReSetPasswordByKeyUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'IGAM/Authorize/UpdatePasswordByKey', 'POST'
    );
  }

  // resend device verification code
  getReSendUserDeviceVerificationCodeUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'IGAM/Authorize/DeviceVerificationCode/ReSend', 'POST'
    );
  }
  // check user device verification code
  checkUserDeviceVerificationCodeUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'IGAM/Authorize/DeviceVerificationCode/Check', 'POST'
    );
  }

  // check user MFA verification code
  checkMFAVerificationCodeUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'IGAM/Authorize/MFA/Check', 'POST'
    );
  }
    
  // get available groups
  getAvailableGroupsUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'IGAM/AvailableGroups', 'GET'
    );
  }
    
  // get user available MFA methods
  getGroupMembersUrl(groupId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'IGAM/GroupMembers?groupId=' + groupId, 'GET'
    );
  }

  /** IGAM API address end */ 

    
  getTrackingApiErrorUrl() {
    return this.getBaseUrl() + this.version + 'Tracking/ApiError';
  }

  getCreateUserUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Authorize/CreateAccount', 'POST'
    );
  }

  getUserSavedQuoteUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Quote/SavedQuotes', 'GET'
    );
  }

  getUserArchivedQuotesUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Quote/ArchivedQuotes', 'GET'
    );
  }

  getUserNoticeCounterUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Notices/Count', 'GET'
    );
  }

  // Notice start

  getSystemNoticesUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Notices/SystemNotices', 'GET'
    );
  }

  getUserNoticeListUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Notices/List', 'GET'
    );
  }

  getUserNoticeUrl(noticeId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Notices/Notice?noticeId=' + noticeId, 'GET'
    );
  }

  deleteUserNoticeUrl(noticeId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Notices/Notice?noticeId=' + noticeId, 'DELETE'
    );
  }


  getUserNoticeStartupNoticeUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Notices/StartUp', 'GET'
    );
  }

  markAllNoticeReadUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Notices/MarkAllRead', 'PUT'
    );
  }


  getInfoPageUrl(pageCode: string): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PublicData/InfoPage?key=' + pageCode, 'GET'
    );
  }

  getQprAndQeVersionUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PublicData', 'GET'
    );
  }

  getQMTrackingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Tracking', 'PUT'
    );
  }




  // pa function
  getPaAccessTokenUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PaUser/Authorize/GetAccess', 'POST'
    );
  }

  getUpdatePaPasswordUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PaUser/Authorize/UpdatePasswordByKey', 'PUT'
    );
  }

  getResetPaPasswordUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PaUser/Authorize/ReSetPassword', 'POST'
    );
  }

  getReSetPaPasswordByKeyUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PaUser/Authorize/UpdatePasswordByKey', 'POST'
    );
  }

  getUserSavedQuoteDetailUrl(quoteId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Quote/SavedQuoteDetail?quoteId=' + quoteId, 'GET'
    );
  }


  deleteUserSavedQuoteUrl(quoteId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Quote/SavedQuote?quoteId=' + quoteId, 'DELETE'
    );
  }


  getUserProviderSettingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/ProviderSetting', 'GET'
    );
  }


  resetUserProviderSettingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/ProviderSetting', 'DELETE'
    );
  }


  updateUserProviderSettingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/ProviderSetting', 'PUT'
    );
  }

  removeUserCrossProviderUrl(providerId: number, benefitId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'User/Setting/CrossProviderSetting?providerId=' + providerId + '&benefitId=' + benefitId, 'DELETE'
    );
  }


  getUserQmSettingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/QmSiteSetting', 'GET'
    );
  }

  updateUserQmSettingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/QmSiteSetting', 'PUT'
    );
  }
    
  updateQuoteNameUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Quote/QuoteName', 'POST'
    );
  }

  updateUserProviderSpecialSettingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/SpecialProviderSetting', 'PUT'
    );
  }

  getDefaultProviderOccupationSettingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/DefaultOccupationSetting', 'GET'
    );
  }


  getUserProviderOccupationSettingUrl(occId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/OccupationSetting?qmOccId=' + occId, 'GET'
    );
  }

  resetUserProviderOccupationSettingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/OccupationSetting', 'DELETE'
    );
  }


  updateUserProviderOccupationSetting(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/OccupationSetting', 'PUT'
    );
  }

  getUserDetailUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Detail', 'GET'
    );
  }

  updateUserDetailUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Detail', 'PUT'
    );
  }

  updateUserLogoUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Logo', 'FILE_UPLOAD'
    );
  }

  removeUserLogoUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Logo', 'DELETE'
    );
  }


  updateUserAvatarUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Avatar', 'FILE_UPLOAD'
    );
  }

  removeUserAvatarUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Avatar', 'DELETE'
    );
  }

  uploadSoaReportCoverPageImageUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/SOASection/CoverImage', 'FILE_UPLOAD'
    );
  }


  removeSoaReportCoverPageImageUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/SOASection/CoverImage', 'DELETE'
    );
  }

  getNeedAnalysisSettingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/NeedSetting', 'GET'
    );
  }

  updateNeedAnalysisSettingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/NeedSetting', 'PUT'
    );
  }

  // soa report themes
  getSoaThemesUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/SoaSetting', 'GET'
    );
  }

  updateUserSoaThemeUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/SoaSetting', 'PUT'
    );
  }


  updateUserNameUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Authorize/UserName', 'PUT'
    );
  }

  updateUserPasswordUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Authorize/UpdatePassword', 'PUT'
    );
  }

  updatePaUserPasswordUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PaUser/Authorize/UpdatePassword', 'POST'
    );
  }
    
  getAllowQmSuperPAStatusUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Authorize/AllowQmSuperPA', 'GET'
    );
  }
    
  updateAllowQmSuperPAStatusUrl(isAllowQmSuperPA:boolean): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Authorize/AllowQmSuperPA?isAllow=' + isAllowQmSuperPA, 'POST'
    );
  }
    


  // resend device verification code for PA user login
  getReSendPaUserDeviceVerificationCodeUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PaUser/Authorize/DeviceVerificationCode/ReSend', 'POST'
    );
  }


  getUserLoginDevicesUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Authorize/MFA/LoginDevices', 'GET'
    );
  }

  getPaUserLoginDevicesUrl(paUserId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PaUser/Authorize/MFA/LoginDevices?paUserId=' + paUserId, 'GET'
    );
  }

  deleteLoginDevicesUrl(deviceId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Authorize/MFA/LoginDevice?deviceIndex=' + deviceId, 'DELETE'
    );
  }

  deletePaLoginDevicesUrl(deviceId: number, paUserId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PaUser/Authorize/MFA/LoginDevice?deviceIndex=' + deviceId + '&paUserId=' + paUserId, 'DELETE'
    );
  }

  updateLoginDeviceNameUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Authorize/MFA/LoginDeviceName', 'POST'
    );
  }

  updatePaLoginDeviceNameUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PaUser/Authorize/MFA/LoginDeviceName', 'POST'
    );
  }

  getReportHistoryListUrl(quoteId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Quote/ReportHistory?quoteId=' + quoteId, 'GET'
    );
  }



  getNeedAnalysisUrl(ncId: number, quoteId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/NeedAnalysis?ncId=' + ncId + '&quoteId=' + quoteId, 'GET'
    );
  }

  updateNeedAnalysisUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/NeedAnalysis', 'POST'
    );
  }


  getPolicyDocumentRequiredDataUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PolicyDoc/RequiredData', 'GET'
    );
  }


  getQprRequiredDataUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Research/RequiredData', 'GET'
    );
  }

  getPolicyDocumentListUrl(companyId, benefitId, productId, documentId, month, year): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version
      + 'PolicyDoc?companyId=' + companyId
      + '&benefitIdsString=' + benefitId
      + '&productId=' + productId
      + '&documentId=' + documentId
      + '&month=' + month
      + '&year=' + year, 'GET'
    );
  }

  // quote
  getDoCrunchUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Quote', 'POST'
    );
  }


  getQprPolicyWordingRequiredDataUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PolicyWording/RequiredData', 'GET'
    );
  }


  getQprItemDetailListUrl(productId: number, qprItemId: number, selectedProviderIds: string) {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'PolicyWording?productId=' + productId
      + '&qprItemId=' + qprItemId
      + '&selectedProviderIds=' + selectedProviderIds, 'GET'
    );
  }

  getQuoteQprItemDetailListUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'PolicyWording/MixPolicyWording'
      , 'POST'
    );
  }

  getQmadsUrl(position: number) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PublicData/QmAds?position=' + position, 'GET'
    );
  }




  getCheckFspDetailUrl(fsp: string) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PublicData/CheckFsp?fsp=' + fsp, 'GET'
    );
  }

  getCheckEmailUrl(email: string) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PublicData/CheckEmail?email=' + email, 'GET'
    );
  }


  getQprResultV3Url(quoteId: number, pCodes: string, cIndex: number, bIndex: number, orderBy: number) {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Research/QprRating?quoteId=' + quoteId
      + '&pCodes=' + pCodes
      + '&cIndex=' + cIndex
      + '&bIndex=' + bIndex
      + '&orderBy=' + orderBy, 'GET'
    );
  }


  getHeadToHeadResultUrl(quoteId: number, pCodes: string, cIndex: number, bIndex: number) {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Research/HeadToHead?quoteId=' + quoteId
      + '&pCodes=' + pCodes
      + '&cIndex=' + cIndex
      + '&bIndex=' + bIndex, 'GET'
    );
  }


  getQuoteResultReportUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Report/QuoteResult',
      'POST'
    );
  }


  getBankPremiumUrl(quoteId: number, selectedProviderQprCodes: string) {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Quote/QuoteForAll?appId=' + quoteId
      + '&cqCodes=' + selectedProviderQprCodes,
      'GET'
    );
  }

  get10YearPremiumEstimateUrl(quoteId: number, selectedProviderQprCodes: string, clientIndex: number, benefitIndex: number) {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Quote/TenYearsPremium?appId=' + quoteId
      + '&cqCodes=' + selectedProviderQprCodes
      + '&cId=' + clientIndex
      + '&bId=' + benefitIndex,
      'GET'
    );

  }

  getHeadToHeadReportUrl() {

    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Report/HeadToHead',
      'POST'
    );

  }




  getDefaultCommissionSettingItem() {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'User/Setting/DefaultCommissionSetting',
      'GET'
    );
  }

  getUserCommissionSettings() {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'User/Setting/CommissionSetting',
      'GET'
    );
  }

  addOrUpdateUserCommissionSettings() {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'User/Setting/CommissionSetting',
      'POST'
    );
  }


  getUserProfileUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'User/Profile',
      'GET'
    );
  }

  updateUserProfileUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'User/Profile',
      'PUT'
    );
  }



  // licenase service start
  getAllAvailableLicensesUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Licenses/All', 'GET'
    );
  }

  getAllAvailableLicensesForPublicUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Licenses/Public', 'GET'
    );
  }


  getOwnLicensesUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Licenses/Own', 'GET'
    );
  }

  cancelLicenseUrl(licenseId: number) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Order/Cancel?licenseId=' + licenseId, 'DELETE'
    );
  }

  cancelAllLicensesUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Order/CancelAll', 'DELETE'
    );
  }

  getLicenseUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/License', 'GET'
    );
  }

  getPaymentMethodUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/PaymentMethods', 'GET'
    );
  }

  getAllPaymentMethodsUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/AllPaymentMethods', 'GET'
    );
  }

  addPaymentMethodUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/PaymentMethod', 'POST'
    );
  }

  updatePaymentMethodUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/PaymentMethod', 'PUT'
    );
  }

  getBillingAddressesUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/BillingAddresses', 'GET'
    );
  }

  addOrUpdateBillingAddressUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/BillingAddress', 'POST'
    );
  }

  getPrepareOrderUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Order', 'POST'
    );
  }

  getOrderUrl(orderStamp: string) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Order?orderStamp=' + orderStamp, 'GET'
    );
  }


  submitOrderUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Order', 'PUT'
    );
  }


  getOrderStatusUrl(orderId: number) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Order/Status?orderId=' + orderId, 'GET'
    );
  }


  getUserSubscriptionInvoicesUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Invoices', 'GET'
    );
  }




  // license service end


  getUnderwritingUrl(quoteId: number, selectedQprProviderIds: string,
    clientIndex: number, bmi: number, height: number = 0, weight: number = 0,
    lifeInForce: number = 0, traumaInForce: number = 0, tpdInForce: number = 0) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version
      + 'Research/MedicalRequirement?quoteId=' + quoteId
      + '&qpCodes=' + selectedQprProviderIds
      + '&ci=' + clientIndex
      + '&bmi=' + bmi
      + '&height=' + height
      + '&weight=' + weight
      + '&lifeInForce=' + lifeInForce
      + '&traumaInForce=' + traumaInForce
      + '&tpdInForce=' + tpdInForce

      , 'GET'
    );
  }



  cloneUserSavedQuoteUrl(quoteId: number) {

    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Quote/CloneQuote?appId=' + quoteId, 'GET'
    );
  }


  getReportV2Url() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Report/FullReport', 'POST'
    );
  }

  getSoaReportUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Report/SOA', 'POST'
    );
  }

  getAppReports(appId: number) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Report/AppReports?appId=' + appId, 'GET'
    );
  }


  getEmailReports() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Report/EmailReports', 'POST'
    );
  }





  getSOAReplacementItems(defSectionId: number | null) {
    let query: string = "";
    if (defSectionId !== null) {
      query = '?defSectionId=' + defSectionId;
    }
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/SOAReplacementItems' + query, 'GET'
    );
  }

  getUserSOASections() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/SOASections', 'GET'
    );
  }

  resetUserSOASections() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/SOASections', 'DELETE'
    );
  }


  updateUserSOASectionsOrder() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/SOASections', 'PUT'
    );
  }


  getUserSOASection(userOwnSectionId, defSectionId) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/SOASection?userOwnSectionId=' + userOwnSectionId + '&defSectionId=' + defSectionId, 'GET'
    );
  }

  getUserSOAReportCustomisations() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/SOAReportCustomisations', 'GET'
    );
  }

  updateUserSOAReportCustomisation() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/SOAReportCustomisation', 'PUT'
    );
  }

  createOrUpdateUserSOASection() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/SOASection', 'PUT'
    );
  }

  getUserIsUsingGroupSoa() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/IsUsingGroupSoa', 'GET'
    );
  }
    
  createSOAPreviewReportUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Report/PreviewSOA', 'POST'
    );
  }

  updateUserIsUsingGroupSoa(useOwnTheme: boolean) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/IsUsingGroupSoa?usingGroupTheme=' + useOwnTheme, 'PUT'
    );
  }

  getPolicyWordingReportUrl(productId: number, qprItemId: number, selectedProviderIds: string) {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Report/PolicyWording?productId=' + productId
      + '&qprItemId=' + qprItemId
      + '&selectedProviderIds=' + selectedProviderIds, 'GET'
    );
  }


  getMixProductPolicyWordingReport() {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Report/MixPolicyWording', 'POST'
    );
  }


  getRequiredJsonUrl() {
    return '/assets/requiredData.json';
  }


  getVerifyGoogleReCaptchaUrl() {
    return this.getBaseUrl() + this.version + 'Tracking/VerifyGEC';
  }























  // no longer using
  getUserFeedbackUrl() {
    return this.getBaseUrl() + this.version + 'User/UserFeedback';
  }

  getAnonymousFeedbackUrl() {
    return this.getBaseUrl() + this.version + 'User/AnonymousFeedback';
  }


  getSystemAlertsUrl() {
    return this.getBaseUrl() + this.version + 'User/Notice/GetAvailableSystemAlerts';
  }


  getInsurerSelectionUrl() {
    return this.getBaseUrl() + this.version + 'QPR/InsurerSelectionList/Get';
  }








  // 2 factor login api
  getVerificationMethodsUrl() {
    return this.getBaseUrl() + this.version + 'User/Security/GetVerificationMethods';
  }

  getCreateNewVerificationMethodUrl() {
    return this.getBaseUrl() + this.version + 'User/Security/CreateNewVerificationMethod';
  }

  getRemoveVerificationMethodUrl() {
    return this.getBaseUrl() + this.version + 'User/Security/RemoveVerificationMethod';
  }

  getCheckAndUpdateVerificationMethodUrl() {
    return this.getBaseUrl() + this.version + 'User/Security/CheckAndUpdateVerificationMethod';
  }

  getReSendVerificationMethodCodeUrl() {
    return this.getBaseUrl() + this.version + 'User/Security/ReSendVerificationMethodCode';
  }

  getSetDefaultVerificationMethodUrl() {
    return this.getBaseUrl() + this.version + 'User/Security/SetDefaultVerificationMethod';
  }

  getUserDevicesUrl() {
    return this.getBaseUrl() + this.version + 'User/Security/GetUserDevices';
  }

  getRemoveUserDeviceUrl() {
    return this.getBaseUrl() + this.version + 'User/Security/RemoveUserDevice';
  }


  getUpdateUserDeviceNameUrl() {
    return this.getBaseUrl() + this.version + 'User/Security/UpdateUserDeviceName';
  }

  // Adviser claim experiences

  getACEInfoItemListUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Ace/AdviserClaimsExperiences', 'GET'
    );
  }

  getACEDetailItemUrl(aceId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Ace/AdviserClaimsExperience?aceId=' + aceId, 'GET'
    );
  }

  deleteACEDetailItemUrl(aceId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Ace/AdviserClaimsExperience?aceId=' + aceId, 'DELETE'
    );
  }

  getACEProvidersUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Ace/Providers', 'GET'
    );
  }

  getACESelectOptionGroupsUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Ace/SelectOptionGroups', 'GET'
    );
  }

  postACEUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Ace/AdviserClaimsExperience', 'POST'
    );
  }

  postACECommentUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Ace/Comment', 'POST'
    );
  }

  // CPD
  putCpdDataUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PublicData/CPD', 'PUT'
    );
  }

  // standalone head to head
  postStandaloneH2HUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Research/SHeadToHead', 'POST'
    );
  }

  postStandaloneH2HReportUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Report/SHeadToHead', 'POST'
    );
  }
    
  userDeviceTrackingUrl() {
    return new HttpRequestUrl(this.getBaseUrl() + this.version + 'Tracking/UserDeviceTracking', 'POST');
  }

}