<div class="row qm-bg-catskillWhite py-block resetPW-page">
  <div class="container text-center">
    <div class="resetPW-card mx-auto py-5 px-3 px-sm-5 bg-white col-lg-7">
      
        <form [formGroup]="resetPasswordForm"
              (ngSubmit)='doResetPassword(resetPasswordForm.value);
                         loginService.doGoogleTracking("InternalGroupAdminLogin", "do reset password", "")'>
          <ul class="list-group border-none">
            <h2 class="mb-5">Reset Password</h2>

            <li class="list-group-item px-0">
              <mat-form-field>
                <input matInput type="email" placeholder='User Email Address' formControlName="UserName" required>
              </mat-form-field>
            </li>

            <li class="list-group-item px-0">
              <mat-form-field>
                <input matInput [type]="hidePassword ? 'password' : 'text'" formControlName="Password" placeholder="Password" required (keyup)="onPasswordValueChanges($event.target.value)">
                <button mat-icon-button type="button" matSuffix (click)="hidePassword = !hidePassword" [disableRipple]="true">
                  <span class="material-icons">{{hidePassword ? 'visibility_off' : 'visibility'}}</span>
                </button>
              </mat-form-field>
            </li>

            <li class="list-group-item px-0">
              <mat-form-field>
                <input matInput [type]="hideConfirmPassword ? 'password' : 'text'" formControlName="ConfirmPassword" placeholder="Confirm Password"
                  required>
                <button mat-icon-button type="button" matSuffix (click)="hideConfirmPassword = !hideConfirmPassword" [disableRipple]="true">
                  <span class="material-icons">{{hidePassword ? 'visibility_off' : 'visibility'}}</span>
                </button>
                <mat-error *ngIf="resetPasswordForm.errors && resetPasswordForm.errors.MatchPassword">
                  {{sharedFunction.getUiMessageByCode("Share-ERROR-PasswordNotMatch")}}
                </mat-error>
              </mat-form-field>
            </li>

            <li class='list-group-item px-0 text-left'>
              <div class="alert alert-info" role="alert">
                <h5 class="alert-heading">{{sharedFunction.getUiMessageByCode("Share-INFO-PasswordSecurityHeader")}}</h5>
                <p class="qm-text-sm">
                  {{sharedFunction.getUiMessageByCode("Share-INFO-PasswordSecurity")}}
                </p>
                <hr>
                <ng-container *ngFor="let item of passwordSecurity | keyvalue: sharedFunction.originalOrder">
                  <p class="mb-1" [ngClass]="isInitialState ? '': (item.value ? 'text-success' : 'text-danger')">
                    <span class="material-icons align-top mr-2">
                      {{isInitialState ? 'rule': (item.value ? 'check' : 'close')}}
                    </span>
                    {{sharedFunction.getUiMessageByCode("Share-INFO-" + item.key)}}
                  </p>
                </ng-container>
              </div>
            </li>

            <li class="list-group-item px-0">
              <button type="submit" mat-flat-button color="qm-blue" class="btn-block qm-flat-btn-lg"
                [disabled]="!resetPasswordForm.valid || showLoginBtn">
                Reset
              </button>
            </li>

            <li class="list-group-item px-0" *ngIf="messageLogin">
              <div class="alert" role="alert" [ngClass]="isSuccess?'alert-success':'alert-danger'">{{messageLogin}}</div>
            </li>

            <li class="list-group-item px-0" *ngIf="showLoginBtn">
              <a [routerLink]="['/login']" mat-flat-button color="qm-blue" class="btn-block qm-flat-btn-lg"
                (click)='loginService.doGoogleTracking("InternalGroupAdminLogin", "go to login from reset password", "");'>
                Login
              </a>
            </li>
          </ul>
        </form>
    
    </div>
  </div>
</div>
