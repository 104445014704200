<div class='row header header-menu qm-bg-blue-linear-gradient'>
    <div class="container-fluid px-5">
        <!-- after login menu -->
        <nav class="navbar navbar-expand-lg px-0 py-4 align-items-end" *ngIf="loginService.isLogin()">
            <a [routerLink]="['/dashboard']" class="navbar-brand w-50">
                <img src="../../../assets/images/qm-logos/Quotemonster-IGA-Logo.png?v=2" alt="qm-logo">
            </a>
            <button mat-icon-button [disableRipple]="true" class="navbar-toggler scale-150" type="button"
                    data-toggle="collapse" aria-expanded="false" aria-label="Toggle navigation">
                <span class="material-icons navbar-toggler-icon text-white"
                      aria-hidden="false" aria-label="menu" (click)='showCollapseNav = !showCollapseNav'>
                    menu
                </span>
            </button>
            <div class="collapse navbar-collapse justify-content-between align-items-center"
                 [ngClass]="{'show border-top pb-5 mt-3': showCollapseNav}">
                <ul class="navbar-nav align-items-center">
                    <li class="nav-item px-2">
                        <a [routerLink]="['/group-members']" class="nav-link qm-text-lg font-weight-bolder text-nowrap"
                           [routerLinkActive]="['link-active']"
                           (click)='showCollapseNav = false;loginService.doGoogleTracking("InternalGroupAdminNavMenu", "groupMembers", "");'>
                            Members
                        </a>
                    </li>
                    <li class="nav-item px-4">
                        <a [routerLink]="['/view-invoice']" class="nav-link qm-text-lg font-weight-bolder text-nowrap"
                           [routerLinkActive]="['link-active']"
                           (click)='showCollapseNav = false;loginService.doGoogleTracking("InternalGroupAdminNavMenu", "viewInvoice", "");'>
                            Invoice
                        </a>
                    </li>

                    <li class="nav-item px-2">
                        <a [routerLink]="['/soa-template']" class="nav-link qm-text-lg font-weight-bolder text-nowrap"
                           [routerLinkActive]="['link-active']"
                           (click)='showCollapseNav = false;loginService.doGoogleTracking("InternalGroupAdminNavMenu", "soaTemplate", "");'>
                            SOA
                        </a>
                    </li>
                    <!-- <li class="nav-item px-2">
                        <button mat-button class='text-white qm-text-lg font-weight-bolder text-nowrap'
                                aria-label="logout" (click)='logout()' title='logout'>
                            <span class='text-truncate mr-3'>Logout</span>
                            <span class="material-icons">logout</span>
                        </button>
                    </li> -->
                </ul>
                <button mat-button class='text-white qm-text-lg font-weight-bolder text-nowrap'
                        aria-label="logout" (click)='logout();loginService.doGoogleTracking("InternalGroupAdminNavMenu", "logout", "")' title='logout' [ngClass]="{'w-100': showCollapseNav}">
                    <span class='mr-3 text-transform-none'>{{loginService.getCurrentLoginUserName()}}</span>
                    <span class="material-icons">logout</span>
                </button>
            </div>
        </nav>

        <!--before login menu-->
        <nav class="navbar navbar-expand-lg px-0 py-4 align-items-end" *ngIf="loginService.isLogin() === false">
            <a [routerLink]="['/home']" class="navbar-brand w-75">
                <img src="../../../assets/images/qm-logos/Quotemonster-IGA-Logo.png?v=2" alt="qm-logo">
            </a>
        </nav>
    </div>
</div>