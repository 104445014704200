import { Component, OnInit } from '@angular/core';
import { GroupMember } from 'src/app/models/IGA-group/group-member.model';
import { Group, UIGroup } from 'src/app/models/IGA-group/group.model';
import { ComponentBaseClass } from 'src/app/service/base';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { UserService } from 'src/app/service/user.service';

@Component({
    selector: 'app-group-members',
    templateUrl: './group-members.component.html',
    styleUrls: ['./group-members.component.scss'],
})
export class GroupMembersComponent extends ComponentBaseClass implements OnInit {
    groups: UIGroup[] = [];
    groupMembers: GroupMember[] = [];
    errorMessage: string = '';
    defaultGroupId: number = -1;
    constructor(public userService: UserService, public sharedFunction: SharedFunctionService) {
        super();
    }

    ngOnInit(): void {
        this.errorMessage = '';
        this.getData();
    }

    getData(): void {
        this.showDinoLoading();
        this.groups = [];
        this.userService.getAvailableGroups((groupResponse: Group[]) => {
            if (groupResponse.length > 0) {
                let counter: number = 0;
                for (let group of groupResponse) {
                    // create UIGroup (add members to group object)
                    let newUIGroup = new UIGroup();
                    newUIGroup.Description = group.Description;
                    newUIGroup.GroupName = group.GroupName;
                    newUIGroup.Id = group.Id;
                    // get each group's members
                    this.userService.getGroupMembers(group.Id, (membersResponse:GroupMember[]) => {
                        newUIGroup.Members = membersResponse;
                        this.groups.push(newUIGroup);
                        counter++;
                        if (counter === groupResponse.length) {
                            this.groups.sort((a, b) => a.Id - b.Id);
                            this.defaultGroupId = this.groups[0].Id;
                            this.closeDinoLoading();
                        }
                    });
                }
            } else {
                this.errorMessage = this.sharedFunction.getUiMessageByCode('GroupMembers-ERROR-NoAvailableGroups');
                this.closeDinoLoading();
            }
        });
    }
}
