import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule, MatStepperIntl } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { A11yModule } from '@angular/cdk/a11y';
import { BidiModule } from '@angular/cdk/bidi';
import { OverlayModule } from '@angular/cdk/overlay';
import { PlatformModule } from '@angular/cdk/platform';
import { ObserversModule } from '@angular/cdk/observers';
import { PortalModule } from '@angular/cdk/portal';
import { DragDropModule } from '@angular/cdk/drag-drop';

// app start
import { AppComponent } from './app.component';
import { NavMenuComponent } from './components/navmenu/navmenu.component';

// login register forgotten password
import { LoginComponent } from './components/login/login.component';
import { VerifyDeviceComponent } from './components/login/verify-device.component';
import { ForgottenPasswordDialogComponent } from './components/login/forgotten-password-dialog.component';
import { ResetPasswordComponent } from './components/login/reset-password.component';
import { UpdatePaPasswordComponent } from './components/login/update-pa-password.component';

// qm data service
import { LoginService } from './service/login.service';
import { ApiAddressService } from './service/api.address.service';
import { DataLoaderService } from './service/data.loader.service';
import { DataLoader } from './service/data.loader';
import { UserService } from './service/user.service';
import { UserSecurityService } from './service/user.security.service';
import { SysConfigService } from './service/sys.config';

// tools
import { SafeUrlPipe } from './service/safe.url.pipe';
import { SafeHtmlPipe } from './service/safe.html.pipe';
import { AutofocusDirective } from './service/autofocus.directive';
import { CanDeactivateGuard } from './tools/can-deactivate-guard.service';
import { ConfirmMessageDialogService } from './components/shared/confirm-message-dialog.service';
import { ConfirmMessageDialogComponent } from './components/shared/confirm-message-dialog.component';
import { SharedFunctionService } from './service/shared.function.service';
import { ResizeService } from './service/screen.resize.service';

// routing
import { AppRoutingModule } from './app-routing.module';
import { Router, UrlSerializer } from '@angular/router';
import { ApiService } from './service/api.service';
import { LowerCaseUrlSerializer } from './tools/lower.case.url.serializer';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { AutoSelectDirective } from './tools/auto.select.directive';
import { MfaMethodSelectComponent } from './components/login/mfa-method-select/mfa-method-select.component';
import { RealignInkBarDirective } from './tools/realign-ink-bar.directive';
import { ViewInvoiceComponent } from './components/view-invoice/view-invoice.component';
import { GroupMembersComponent } from './components/group-members/group-members.component';
import { SOATemplateComponent } from './components/soa-template/soa-template.component';

@NgModule({
    declarations: [
        AppComponent,
        NavMenuComponent,
        LoginComponent,
        VerifyDeviceComponent,
        ResetPasswordComponent,
        ForgottenPasswordDialogComponent,
        UpdatePaPasswordComponent,
        ConfirmMessageDialogComponent,
        SafeUrlPipe,
        SafeHtmlPipe,
        AutofocusDirective,
        AutoSelectDirective,
        MfaMethodSelectComponent,
        RealignInkBarDirective,
        ViewInvoiceComponent,
        GroupMembersComponent,
        SOATemplateComponent,
    ],

    entryComponents: [ForgottenPasswordDialogComponent, ConfirmMessageDialogComponent],

    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatBadgeModule,
        MatStepperModule,
        A11yModule,
        BidiModule,
        ObserversModule,
        OverlayModule,
        PlatformModule,
        PortalModule,
        MatBottomSheetModule,
        DragDropModule,
        MatSidenavModule,
        AppRoutingModule,
        EditorModule,
    ],
    providers: [
        ApiAddressService,
        SysConfigService,
        LoginService,
        DataLoaderService,
        DataLoader,
        UserService,
        UserSecurityService,
        MatStepperIntl,
        SharedFunctionService,
        ResizeService,
        CanDeactivateGuard,
        ConfirmMessageDialogService,
        ApiService,
        DatePipe,
        {
            provide: UrlSerializer,
            useClass: LowerCaseUrlSerializer,
        },

        {
            provide: MAT_DATE_LOCALE,
            useValue: 'en-NZ',
        },

        { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(router: Router) {}
}
