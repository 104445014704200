import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../service/login.service';
import { UserService } from '../../service/user.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: "nav-menu",
  templateUrl: "./navmenu.component.html",
  styleUrls: ["./navmenu.component.scss"],
})
export class NavMenuComponent implements OnInit {

  collapse: string = "collapse";
  showCollapseNav: boolean = false;



  constructor(
    public router: Router,
    public loginService: LoginService,
    public userService: UserService,
  ) {
  }

  ngOnInit() {
    this.showCollapseNav = false;
  }

  collapseNavbar(): void {
    if (this.collapse.length > 1) {
      this.collapse = "";
    } else {
      this.collapse = "collapse";
    }
  }


  logout() {
    this.loginService.logout();
  }

  hideMenu(): boolean {
    this.showCollapseNav = false;
    return true;
  }
}
