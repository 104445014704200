import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PaLoginValidation } from '../../tools/form.validation';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../service/login.service';
import { DataLoader } from '../../service/data.loader';
import { QuoteRequiredData } from '../../models/quote.required.data';
import { BaseUserModel, DeviceCodeItem } from '../../models/user';
import { UserDetail } from '../../models/user.detail';
import { ComponentBaseClass } from '../../service/base';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { ForgottenPasswordDialogComponent } from './forgotten-password-dialog.component';
import { SysConfigService } from 'src/app/service/sys.config';
import { GeneralResponseMessage } from 'src/app/models/messages/general.response.message';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends ComponentBaseClass implements OnInit, AfterViewInit {

  userDetail: UserDetail = new UserDetail();
  userLogin: BaseUserModel = new BaseUserModel();
  loginForm: FormGroup;
  messageLogin: string;
  messageSignup: string;
  dobMonths = [];
  dobYears = [];
  quoteRequiredData: QuoteRequiredData;
  savedUserEmail: string = '';
  savedUserDeviceCode: string = '';
  userDeviceCodeItems: DeviceCodeItem[];
  hasSavedUserEmail: boolean = false;
  isPaLogin: boolean = false;

  thirdUserName: string = null;
  thirdUserPassword: string = null;

  showVerificationCode: boolean = false;
  reSendCodeTimmer: number = 60 * 5;
  timeInterval: any;


  public selectedIndex: number = 0;

  browserFingerprint: string = '';
  hidePassword:boolean=true;




  // Use "constructor"s only for dependency injection
  constructor(
    public loginService: LoginService,
    private loginFormBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    private dataLoader: DataLoader,
    private sharedFunction: SharedFunctionService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public router: Router,
    private systemConfig: SysConfigService,
  ) {
    super();
  }

  // Here you want to handle anything with @Input()'s @Output()'s
  // Data retrieval / etc - this is when the Component is "ready" and wired up
  ngOnInit() {

    this.init();

  }


  init() {
    this.showVerificationCode = false;
    this.quoteRequiredData = this.dataLoader.getRequiredData();

    this.savedUserEmail = this.loginService.getSavedUserEmail();
    if (this.savedUserEmail.length > 0) {
      this.hasSavedUserEmail = true;

    }

    this.savedUserDeviceCode = this.loginService.getDeviceCodeFromQmDeviceCode();

    this.userDeviceCodeItems = this.loginService.getDeviceCodeItems()
    // get paramap from url

    this.thirdUserName = this.route.snapshot.queryParamMap.get('id');
    this.thirdUserPassword = this.route.snapshot.queryParamMap.get('token');


    this.buildForm();
  }


  ngAfterViewInit() {

    // do third login
    if (this.thirdUserName && this.thirdUserPassword) {

      this.loginForm.controls.UserName.setValue(this.thirdUserName);
      this.loginForm.controls.UserPassword.setValue(this.thirdUserPassword);

      // wait 1 second then call do login
      this.showDinoLoading();
      window.setTimeout(() => {
        this.doLogin(this.loginForm.value);
      }, 1000);
    } else if (this.route.snapshot.paramMap.get('id')) {
      // put user login email into form
      this.loginForm.controls.UserName.setValue(this.route.snapshot.paramMap.get('id'));
    }

  }


  buildForm(): void {
    this.userDetail = new UserDetail();


    this.loginForm = this.loginFormBuilder.group(
      {
        UserName: [
          this.savedUserEmail,
          Validators.compose([Validators.required, Validators.email]),
        ],
        UserPassword: [null, Validators.required],
        RememberEmail: [this.hasSavedUserEmail],
        IsPaLogin: [this.isPaLogin],
        PaEmail: [null, Validators.email],
        validate: "",
      },
      {
        validator: PaLoginValidation.PaValidation,
      }
    );
  }



  doLogin(user): void {
    this.showDinoLoading();
      
    this.loginService.clearData();
      
      
    let userModel = new BaseUserModel();

    userModel.UserName = user.UserName;
    userModel.Password = user.UserPassword;
      
    userModel.DeviceCode = this.loginService.getDeviceCode(userModel.UserName);
    userModel.DeviceName = navigator.userAgent;

    // get user login event model
    // show loading
    this.loginService.getAccessToken(userModel, (response) => {
      this.messageLogin = '';

      if (response && response.Messages && response.Messages.length) {
          // error: wrong username or password
          for (let m of response.Messages) {
              this.messageLogin = this.messageLogin + m.Message + '</ br>';
          }
      } else if (response && response.IsRequiredToAddMFA) {
          // required MFA and haven't added any MFA methods so need to add an MFA method
          // PA login response: no refresh token, access token, license; has device code
          // Standard login response: has refresh token, access token, license; no device code

          // 1. save userId for getting mfaInfo after selecting MFA method
          this.loginService.setCurrentLoginUserId(response.UserId);
          // 2. save user info for saving user name in case refresh token
          this.loginService.saveUserInfo(response);
          
          this.loginService.updateDeviceCodeItems(
              this.userDeviceCodeItems,
              response.UserName,
              response.UserId,
              response.DeviceCode
          );
          
          // 3. Standard login: save token for getting MFA method
          this.loginService.saveUserToken(response);
          
          // 4. set MFA info to let the isLogin() return false
          let mfaInfo = new GeneralResponseMessage();
          mfaInfo.Message = this.sharedFunction.getUiMessageByCode('Login-INFO-NeedToAddMfaMethod');
          this.loginService.setLoginMfaInfo(mfaInfo, response.UserId);
          // 5. go to MFA select page
          this.router.navigate(['/mfa-methods-select'], { queryParams: { step: 1 } });
          
      } else if (response && response.MfaInfo && response.MfaInfo.MessageCode === 200) {
          // have added a MFA method, use different device, need to check device code
          // response: no refresh token, access token, license; has device code
          // DO MFA check
          // 1. save current login userid
          this.loginService.setCurrentLoginUserId(response.UserId);
          // 2. save user info
          this.loginService.saveUserInfo(response);
          // 3. save device code item
          let deviceCodeUserId = response.UserId;
      
          this.loginService.updateDeviceCodeItems(
              this.userDeviceCodeItems,
              response.UserName,
              deviceCodeUserId,
              response.DeviceCode
          );
          // remove device code saved in old way
          if (this.savedUserDeviceCode) {
              this.loginService.removeDeviceCode();
          }
          // 4. set MFA info
          this.loginService.setLoginMfaInfo(response.MfaInfo, response.UserId);
          // 5. go to V Code verify page
          this.router.navigate(['/verify-device']);
      } else if (response && response.MfaInfo && response.MfaInfo.MessageCode !== 200) {
          // MFA error
          this.messageLogin = response.MfaInfo.Message;
      } else if (response && response.RefreshToken && response.RefreshToken.Token) {
          // login successfully: do not required MFA or have added MFA method and saved the device code
          
          // save token and user info
          this.loginService.saveUserToken(response);
          this.loginService.saveUserInfo(response);
          // go to next page
          this.router.navigate(['/group-members']);
      } else {
          // other errors
          this.messageLogin = this.sharedFunction.getUiMessageByCode('Share-ERROR-Failed');
      }

      this.closeDinoLoading();
    });

    if (user.RememberEmail === true) {
      this.loginService.saveUserEmailCookie(user.UserName);
    } else {
      this.loginService.saveUserEmailCookie('');
    }
  }



  startTimer() {
    this.reSendCodeTimmer = 60 * 5 + 1;
    this.timeInterval = setInterval(() => {
      this.reSendCodeTimmer = this.reSendCodeTimmer - 1;

      if (this.reSendCodeTimmer <= 0) {
        clearInterval(this.timeInterval);
      }
    }, 1000);
  }

  forgottenPassword(user): void {

    this.dialog.open(ForgottenPasswordDialogComponent, {
      data: user,
      maxWidth: "690px",
      width: "80%",
      panelClass: "forgottenPW-panel",
      backdropClass: "forgottenPW-backdrop",
      disableClose: true,
      restoreFocus: false
    });
  }
}
