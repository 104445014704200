<div class='container-fluid p-5 min-height-60vh'>
    <mat-accordion *ngIf="groups.length > 0">
        <mat-expansion-panel *ngFor="let group of groups" [expanded]="defaultGroupId===group.Id">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{group.GroupName}}
                </mat-panel-title>
                <mat-panel-description>
                    {{group.Description}}
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div class='table-responsive mt-4' *ngIf="group.Members.length > 0">
                <table class="table table-striped">
                    <tr class='bg-primary text-white'>
                        <th class='text-nowrap'>First Name</th>
                        <th class='text-nowrap'>Last Name</th>
                        <th class='text-nowrap'>Business Name</th>
                        <th class='text-nowrap'>Phone</th>
                        <th class='text-nowrap'>Address</th>
                        <th class='text-nowrap'>Email (QM User Name)</th>
                        <th class='text-nowrap'>FSP</th>
                        <th class='text-nowrap'>FAP Info</th>
                        <th class='text-nowrap'>Group</th>
                    </tr>
                    <tbody>
                        <tr *ngFor="let member of group.Members">
                            <td>{{member.FirstName ? member.FirstName : '--'}}</td>
                            <td>{{member.LastName ? member.LastName : '--'}}</td>
                            <td>{{member.BusinessName ? member.BusinessName : '--'}}</td>
                            <td>{{member.Phone ? member.Phone : '--'}}</td>
                            <td>{{member.Address ? member.Address : '--'}}</td>
                            <td>{{member.UserName ? member.UserName : '--'}}</td>
                            <td>{{member.FspNumber ? member.FspNumber : '--'}}</td>
                            <td>{{member.FapFspNumber}} {{member.FapName}}</td>
                            <td>{{member.GroupName ? member.GroupName : '--'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="alert alert-danger text-center mt-4" role="alert" *ngIf="group.Members.length === 0">
                {{sharedFunction.getUiMessageByCode('GroupMembers-ERROR-NoGroupMembers')}}
            </div>
        </mat-expansion-panel>
    </mat-accordion>
    <div class="alert alert-danger text-center" role="alert" *ngIf="errorMessage">
        {{errorMessage}}
    </div>
</div>